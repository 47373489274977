@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import "node_modules/ng-zorro-antd/style/entry.less";
@import "node_modules/ng-zorro-antd/layout/style/entry.less";
@import "node_modules/ng-zorro-antd/message/style/entry.less";
@import "node_modules/ng-zorro-antd/notification/style/entry.less";
@import "node_modules/ng-zorro-antd/icon/style/entry.less";
@import "node_modules/ng-zorro-antd/button/style/entry.less";
@import "node_modules/ng-zorro-antd/select/style/entry.less";

@import "node_modules/ng-zorro-antd/ng-zorro-antd.less";

/*
@buttons: primary blue, danger red;

.createbuttons(@iterator:1) when(@iterator <= length(@buttons)) {
  @name: extract(extract(@buttons, @iterator),1);
  @color: extract(extract(@buttons, @iterator),2);
  .cl-@{name} {
    "background-color": @{color}
    "color": darken(@color, 10%)
  }
  .createbuttons(@iterator + 1);
}
.createbuttons();
*/

/*
@success-color: #28a745;
@warning-color: #d43f3a;
@info-color: #17a2b8;
@buttons: success @success-color, warning @warning-color, info @info-color;
.getButtons(@index:1) when(@index <= length(@buttons)) {
  @name: extract(extract(@buttons, @index),1);
  @color: extract(extract(@buttons, @index),2);
  @colorHoverFocus: lighten(@color, 10%);
  @colorActive: darken(@color, 10%);
  .ant-btn-@{name}{
    color: #fff !important;
    background-color: @color !important;
    border-color: @color !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-@{name}-disabled,
  .ant-btn-@{name}.disabled,
  .ant-btn-@{name}[disabled],
  .ant-btn-@{name}-disabled:hover,
  .ant-btn-@{name}.disabled:hover,
  .ant-btn-@{name}[disabled]:hover,
  .ant-btn-@{name}-disabled:focus,
  .ant-btn-@{name}.disabled:focus,
  .ant-btn-@{name}[disabled]:focus,
  .ant-btn-@{name}-disabled:active,
  .ant-btn-@{name}.disabled:active,
  .ant-btn-@{name}[disabled]:active,
  .ant-btn-@{name}-disabled.active,
  .ant-btn-@{name}.disabled.active,
  .ant-btn-@{name}[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .ant-btn-@{name}:hover,
  .ant-btn-@{name}:focus {
    color: #fff !important;
    background-color: @colorHoverFocus !important;
    border-color: @colorHoverFocus !important;
  }
  .ant-btn-@{name}:active,
  .ant-btn-@{name}.active {
    color: #fff !important;
    background-color: @colorActive !important;
    border-color: @colorActive !important;
  }
  .ant-btn-background-ghost.ant-btn-@{name} {
    color: @color !important;
    background: transparent !important;
    border-color: @color !important;
    text-shadow: none !important;
  }
  .ant-btn-background-ghost.ant-btn-@{name}:hover,
  .ant-btn-background-ghost.ant-btn-@{name}:focus {
    color: @colorHoverFocus !important;
    background: transparent !important;
    border-color: @colorHoverFocus !important;
  }
  .ant-btn-background-ghost.ant-btn-@{name}:active,
  .ant-btn-background-ghost.ant-btn-@{name}.active {
    color: @color !important;
    background: transparent !important;
    border-color: @colorActive !important;
  }
  .getButtons(@index + 1);
};

.getButtons();
*/

// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@font-family: "proxima_nova", sans-serif;
@font-size-base: 16px;
@primary-color: #00a0df;
//@warning-color: #d43f3a;
//@danger-color: #d43f3a;

@height-base: 38px;
@line-height-base: 1.235;
@border-radius-base: 4px;
@border-width-base: 2px;

@link-color: #036ecd;
@black: rgba(35, 35, 35, 0.85);

@form-item-label-font-size: 20px;

.aaa-link-color {
  color: #036ecd;
}

.aaa-link-fill {
  fill: #036ecd;
}

.ant-form label {
  font-weight: 400;
  font-size: 20px;
}

.ant-btn {
  &:hover,
  &:focus {
    color: #025aa5;
    border-color: #025aa5;
  }
}

// popover uses ant-btn-sm, this keeps buttons normal size
.ant-popover .ant-btn-sm {
  padding: 7.1px 15px;
}

.ant-btn-primary {

  &:hover,
  &:focus {
    color: #fff;
    border-color: #025aa5;
    background-color: #025aa5;

    &.no-focus {
      border-color: #00a0df;
      background-color: #00a0df;
    }
  }

  &.ant-btn-red {
    border-color: #d43f3a;
    background-color: #d43f3a;

    &:hover,
    &:focus {
      border-color: #cc3333;
      background-color: #cc3333;

      &.no-focus {
        border-color: #d43f3a;
        background-color: #d43f3a;
      }
    }
  }


  &.ant-btn-dark-blue {
    border-color: #0C2B53;
    background-color: #0C2B53;

    &:hover,
    &:focus {
      border-color: #cc3333;
      background-color: #cc3333;

      &.no-focus {
        border-color: #0C2B53;
        background-color: #0C2B53;
      }
    }
  }

  &.ant-btn-medium-blue {
    border-color: #123D74;
    background-color: #123D74;

    &:hover,
    &:focus {
      border-color: #cc3333;
      background-color: #cc3333;

      &.no-focus {
        border-color: #123D74;
        background-color: #123D74;
      }
    }
  }

  &.ant-btn-bright-blue {
    border-color: #25538F;
    background-color: #25538F;

    &:hover,
    &:focus {
      border-color: #cc3333;
      background-color: #cc3333;

      &.no-focus {
        border-color: #25538F;
        background-color: #25538F;
      }
    }
  }
}

h2, h3, h4, h5 {
  font-weight: initial;
  color: unset;
}

.ant-btn {
  height: initial;
}

.ant-modal-body {
  padding: 0;
}

.ant-form-item-label.ant-col {
  //text-align: left;
}

nz-card.blue-banner > .ant-card-head {
  background-color: #00529b;
  color: #ffffff;
  font-size: 24px;
}

.settings-form {
  .ant-form-item-control-input,
  .ant-form-item-label > label {
    //height: inherit;
    //min-height: inherit;
  }
}


body {
  margin: 0 auto;
  //line-height: 21px;
  font-size: 17px;

  &.admin-menu.html {
    margin-top: 57px !important;
  }

  .clickable:hover {
    cursor: pointer;
  }

  &.page-user {
    font-weight: 200;

    h1 {
      font-weight: inherit;
    }
  }

  &.page-node-add,
  &.page-node-edit,
  &.page-admin,
  &.page-user.page-user-edit,
  &.page-taxonomy-term-edit {
    font-family: "Lucida Grande", "Lucida Sans Unicode", sans-serif;
    font-weight: 400;
    font-size: 13px;

    fieldset {
      border: 1px solid #ccc;
      padding: 24px 0 0 0;
      margin: 10px 0;

      &.collapsed {
        min-height: 44px;
      }
    }

    legend {
      width: unset;
      font-size: 13px;
      //font-size: unset;
    }
  }

  &.page-submit-event {
    h1, .intro-heading-1 {
      margin: 10px 0;
    }
  }

  dl, ol, ul {
    margin-bottom: inherit;
  }

  //footer {
  //  position: relative;
  //  z-index: -1;
  //}
}


body.ngserve {
  max-width: 1440px;
  position: relative;

  .centered, .item-center {
    text-align: center;
  }

  .container, .container-fluid {
    padding-right: .9375rem;
    padding-left: .9375rem;
  }

  .container, .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 34em) {
    .container {
      max-width: 34rem;
    }
  }

  @media (min-width: 48em) {
    .container {
      max-width: 45rem;
    }
  }

  @media (min-width: 62em) {
    .container {
      max-width: 60rem;
    }
  }

  @media (min-width: 75em) {
    .container {
      max-width: 72.25rem;
    }
  }
}

aaa-global-settings {
  [nzname="marginTop"],
  [nzname="marginBottom"],
  [nzname="paddingTop"],
  [nzname="paddingBottom"] {
    .ant-radio {
      display: none
    }

    .ant-radio-wrapper {
      display: inline-flex;
      font-size: 30px;
      height: 45px;
      width: 45px;
      justify-content: center;
      border: solid 2px transparent;
      border-radius: 3px;
      align-items: center;

      &.ant-radio-wrapper-checked {
        border-color: red;
      }
    }
  }
}

aaa-unrestricted-form {
  quill-editor {
    position: relative;

    .ql-toolbar {
      position: absolute;
      top: 0;
      width: 100%;
      transform: translateY(-100%);

      background-color: #f5f3f3;
    }

    .ql-container.ql-snow {
      border: none;
    }

    .ql-toolbar {
      display: none;
    }

    &.showToolbar .ql-toolbar {
      display: block;
    }
  }
}

.ql-tooltip {
  z-index: 1;
}

.ql-snow .ql-editor blockquote {
  margin: 0;
}

body .banner-form-title {
  padding-top: 25px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}
